<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.7986 17.957L41.6869 9.80812C40.041 7.61351 36.9673 7.08701 34.6847 8.6087L20.481 18.0779"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M47.7987 29.7078H54.2597C55.687 29.7078 56.8441 30.8648 56.8441 32.2922V40.0454C56.8441 41.4727 55.687 42.6298 54.2597 42.6298H47.7987C44.2303 42.6298 41.3376 39.7371 41.3376 36.1688V36.1688C41.3376 32.6005 44.2303 29.7078 47.7987 29.7078V29.7078Z"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M54.2596 29.7077V24.5389C54.2596 20.9706 51.3669 18.0779 47.7986 18.0779H14.2013C10.6329 18.0779 7.74023 20.9706 7.74023 24.5389V47.7986C7.74023 51.3669 10.6329 54.2596 14.2013 54.2596H47.7986C51.3669 54.2596 54.2596 51.3669 54.2596 47.7986V42.6298"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
