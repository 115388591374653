export default {
  namespaced: true,
  state: {
    userInfo: {},
  },

  getters: {
    GET_USER_INFO: (state) => state.userInfo,
  },

  mutations: {
    MUTATE_USER_INFO: (state, payload) => (state.userInfo = payload),
  },

  actions: {
    SET_USER_INFO: ({ commit }, payload) => commit("MUTATE_USER_INFO", payload),
  },
};
