<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6665 2.5H13.9998V5.83333"
      stroke="#8A6DDE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.3335 7.16667L14.0002 2.5"
      stroke="#8A6DDE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6667 9.83331V13.1666C12.6667 13.9033 12.07 14.5 11.3333 14.5H3.33333C2.59667 14.5 2 13.9033 2 13.1666V5.16665C2 4.42998 2.59667 3.83331 3.33333 3.83331H6.66667"
      stroke="#8A6DDE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
