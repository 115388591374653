<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.25"
      y="1.25"
      width="18.5"
      height="18.5"
      rx="3.25"
      fill="white"
      stroke="#DBDCDD"
      stroke-width="1.5"
    />
  </svg>
</template>
