export default {
  namespaced: true,
  state: {
    drawer: false,
  },

  getters: {
    GET_DRAWER: (state) => state.drawer,
  },

  mutations: {
    MUTATE_DRAWER: (state, drawerState) => (state.drawer = drawerState),
  },

  actions: {
    ACTION_DRAWER: ({ commit }, payload) => commit("MUTATE_DRAWER", payload),
  },
};
