// ----------------------------------------------------- V-SEPARATOR ---------------------------------------------------

let point = -1,
  charBeforePoint,
  TwoCharBeforePoint,
  charOnPoint,
  pressedKey,
  dotIndexPlace,
  dotIndexPlacePast,
  wrongCharIndex,
  generalState,
  pastSpecChar,
  dot,
  digitsAfterDot = null,
  separatorAfterEach,
  separatorSign = null,
  maxDigits,
  zeroPermission = false;

export const separator = {
  update(el, binding) {
    const element = el.tagName === "INPUT" ? el : el.querySelector("input");
    const event = new Event("input", { bubbles: true });
    if (binding.value) {
      if (binding.value.separator) {
        if (binding.value.separator.sign) {
          separatorSign = binding.value.separator.sign;
          separatorSign = separatorSign === "." ? "," : separatorSign;
        } else {
          separatorSign = ",";
        }
        if (binding.value.separator.afterEach) {
          separatorAfterEach = binding.value.separator.afterEach;
        } else {
          separatorAfterEach = "3";
        }
      }
      if (binding.value.dot) {
        dot = ".";
        if (binding.value.dot.afterDot) {
          digitsAfterDot = binding.value.dot.afterDot;
        } else {
          digitsAfterDot = null;
        }
      } else {
        dot = "";
      }
      maxDigits = binding.value.maxDigits
        ? Number(binding.value.maxDigits)
        : null;
      zeroPermission = binding.value.zeroAtBegin ? true : false;
    }
    if (element.value) {
      const elementValue = element.value.toString();
      const convertedValue = setSeparator(
        toEnNumber(checkInput(elementValue, dot, separatorSign))
      );
      setCaret(element);
      element.value = convertedValue;
      element.dispatchEvent(event);
    }
  },
};

function checkInput(char, dot = "", sign) {
  if (sign === null) {
    sign = "";
  }
  generalState = new RegExp(`[^\\d۰-۹${dot}${sign}]`);
  // when the input starts with . it turns to 0.
  if (!".".match(generalState) && char.includes(".")) {
    if (/^\./g.test(char)) {
      char = "0".concat(char);
    }
  }
  // Do not let more than one Zero comes at the beginning, but 0.
  if (/^0/g.test(char) && !zeroPermission) {
    if (char.length >= 2 && !/^0\./g.test(char)) {
      const x = char.search(/[^0,]/);
      char = char.slice(x, char.length);
    }
  }

  // remove the wrong character such as alphabet
  let wrongChar = char.match(generalState);
  if (wrongChar) {
    wrongCharIndex = wrongChar.index;
  }
  return char.replace(wrongChar, "");
}

// transform the farsi numbers to english ones
function toEnNumber(number) {
  const toEn = {
    "۰": "0",
    "۱": "1",
    "۲": "2",
    "۳": "3",
    "۴": "4",
    "۵": "5",
    "۶": "6",
    "۷": "7",
    "۸": "8",
    "۹": "9",
  };
  const str = String(number);
  const enStr = Array.from(str).reduce(
    (prev, curr) => `${prev}${toEn[curr] || curr}`,
    ""
  );
  return enStr;
}

//set separate sign after every afterEach variable, but not numbers which have come after dot. It also checks and set one dot (the latest one) if any more dot pressed.
function setSeparator(num) {
  let numbersAfterDot = "";
  if (num.includes(".")) {
    if (num.match(/\./g).length > 1) {
      //when more than one dot is in the input.
      if (pressedKey === 190) {
        //consider the place of last dot gets clicked when one dot is already in the input.
        let before, after;
        if (point > num.indexOf(".")) {
          num = num.replace(/\./g, "");
          before = num.slice(0, point - 1);
          after = num.slice(point - 1);
        } else {
          num = num.replace(/\./g, "");
          before = num.slice(0, point);
          if (before === "") {
            after = num.slice(1);
          } else {
            after = num.slice(point);
          }
        }

        num = before + "." + after;
      } else {
        //when a number with many numbers gets copy, paste here, the last dot before maxDigits will be considered and the rest will be deleted.
        if (
          maxDigits &&
          num.match(/\d/g).length > maxDigits &&
          /\./.test(num.slice(maxDigits))
        ) {
          let counter = -1;
          let digitsCount;
          let validNumbers;
          do {
            counter += 1;
            validNumbers = num.slice(0, maxDigits + counter);
            digitsCount = validNumbers.match(/\d/g).length;
          } while (digitsCount < maxDigits);
          num = validNumbers;
          if (num.match(/\./g).length > 1) {
            do {
              num = num.replace(/\./, "");
            } while (num.match(/\./g).length !== 1);
          }
        } else {
          do {
            num = num.replace(/\./, "");
          } while (num.match(/\./g).length !== 1);
        }
      }
    }
    //specify the numbers of before and after the dot to get them merged finally
    num = num.replace(new RegExp(separatorSign, "g"), "");

    const dotIndex = num.indexOf(".");
    const digitsAfterDotToCheckToFixed = num.slice(dotIndex + 1);
    if (digitsAfterDot) {
      if (digitsAfterDotToCheckToFixed.length > digitsAfterDot) {
        let numberOfZeros = "",
          counter = 0,
          permission = false;

        if (zeroPermission && !/^0\./.test(num) && num[0] === "0") {
          numberOfZeros = num.search(/[^0]/);
          permission = true;
        }
        // The below 2 line codes are due to toFixed not only might change the last digit for rounding, but also for more than 18 digits for toFixed doesn't work properly.
        const digitsBeforeDot = num.slice(0, dotIndex + 1);
        num = digitsBeforeDot.concat(
          num.slice(
            digitsBeforeDot.length,
            digitsBeforeDot.length + Number(digitsAfterDot)
          )
        );
        // num = Number(num).toFixed(digitsAfterDot);
        if (permission === true) {
          do {
            counter++;
            num = "0" + num;
          } while (numberOfZeros > counter);
        }
      }
    }
    // check and set if there is any max digits limitation
    if (maxDigits) {
      if (num.length > maxDigits) {
        if (num.indexOf(".") < maxDigits) {
          num = num.slice(0, maxDigits + 1);
        } else num = num.slice(0, maxDigits);
      }
    }
    numbersAfterDot = num.slice(dotIndex);
    num = num.slice(0, dotIndex);
  } else {
    if (maxDigits) {
      num = num.replace(new RegExp(separatorSign, "g"), "");
      if (num.length > maxDigits) {
        num = num.slice(0, maxDigits);
      }
    }
  }

  num = num.replace(new RegExp(separatorSign, "g"), "");
  if (separatorAfterEach) {
    return num
      .replace(
        new RegExp(`\\B(?=(\\d{${separatorAfterEach}})+(?!\\d))`, "g"),
        separatorSign
      )
      .concat(numbersAfterDot);
  } else {
    //when no separator is chosen
    return num.concat(numbersAfterDot);
  }
}

//put the caret on the right place in the input when add or delete a character
function setCaret(element) {
  if (point === -1) {
    setTimeout(() => {
      element.setSelectionRange(element.value.length, element.value.length);
    }, 1);
  }
  element.addEventListener("keydown", (e) => {
    point = e.target.selectionStart; //the index of caret
    charOnPoint = element.value.charAt(point); //specify the character of point
    charBeforePoint = element.value.charAt(point - 1); //specify the first character before point
    TwoCharBeforePoint = element.value.charAt(point - 2); //specify the second character before point
    dotIndexPlacePast = element.value.indexOf("."); //specify the index of dot, but consider the index of old dot. Even if you have one dot in the input and then delete it, you still have the index of previous dot here.
    pressedKey = e.keyCode; //keyCode of button which gets clicked
    pastSpecChar = element.value
      .slice(0, point)
      .match(new RegExp(separatorSign, "g"))
      ? element.value.slice(0, point).match(new RegExp(separatorSign, "g"))
          .length
      : 0;
  });
  if (wrongCharIndex || wrongCharIndex === 0) {
    //keep the caret when an invalid character gets clicked.
    element.setSelectionRange(wrongCharIndex, wrongCharIndex);
    setTimeout(() => {
      wrongCharIndex = null;
    }, 1);
  } else {
    //specify the index of only dot in the input if there is any
    dotIndexPlace = element.value.indexOf(".");
    //when no dot is in the input
    if (dotIndexPlace === -1) {
      const input = element.value;
      //when a valid button gets clicked.
      addOrDelValidChar(input, element);
    } else {
      //when a dot is in the input
      if (pressedKey !== 190) {
        // set the caret on the right place when the last input is not dot
        if (point <= dotIndexPlacePast) {
          // set the caret on the right place when we have edit numbers before dot
          let input = element.value;
          input = input.slice(0, input.indexOf("."));
          //when a valid button gets clicked.
          addOrDelValidChar(input, element);
        } else {
          // set the caret on the right place when we have edit numbers after dot
          if (!/\b8\b|\b46\b/g.test(pressedKey)) {
            element.setSelectionRange(point + 1, point + 1);
          } else {
            element.setSelectionRange(point - 1, point - 1);
          }
        }
      } else {
        // set the caret on the right place when the last input is dot
        const dotPlace = element.value.indexOf(".");
        element.setSelectionRange(dotPlace + 1, dotPlace + 1);
      }
    }
  }
}

//add or delete a valid character
function addOrDelValidChar(input, element) {
  if (!/\b8\b|\b46\b/g.test(pressedKey)) {
    // when a valid button gets clicked
    const nowSpecChar = input
      .slice(0, point + 1)
      .match(new RegExp(separatorSign, "g"))
      ? input.slice(0, point + 1).match(new RegExp(separatorSign, "g")).length
      : 0;
    element.setSelectionRange(
      point + 1 + nowSpecChar - pastSpecChar,
      point + 1 + nowSpecChar - pastSpecChar
    );
  } else {
    //when backspace or del button gets clicked
    if (dotIndexPlacePast !== -1 && element.value.indexOf(".") === -1) {
      // when we delete the only dot in the input
      const nowSpecChar = input
        .slice(0, point)
        .match(new RegExp(separatorSign, "g"))
        ? input.slice(0, point).match(new RegExp(separatorSign, "g")).length
        : 0;
      if (TwoCharBeforePoint === "0" && dotIndexPlacePast === 1) {
        if (element.value.length === "0" || zeroPermission) {
          element.setSelectionRange(1, 1);
        } else {
          element.setSelectionRange(0, 0);
        }
      } else if (pastSpecChar === nowSpecChar) {
        element.setSelectionRange(point - 1, point - 1);
      } else {
        element.setSelectionRange(point, point);
      }
    } else {
      const charactersBeforeCaret = input.slice(0, point - 1);
      let nowSpecChar = charactersBeforeCaret.match(
        new RegExp(separatorSign, "g")
      )
        ? charactersBeforeCaret.match(new RegExp(separatorSign, "g")).length
        : 0;
      if (charBeforePoint.includes(separatorSign)) {
        nowSpecChar = pastSpecChar;
      }
      if (charOnPoint.includes(separatorSign) && separatorAfterEach !== "1") {
        if (
          charactersBeforeCaret.charAt(charactersBeforeCaret.length - 1) ===
          separatorSign
        ) {
          pastSpecChar += 1;
        }
      }
      element.setSelectionRange(
        point - 1 + nowSpecChar - pastSpecChar,
        point - 1 + nowSpecChar - pastSpecChar
      );
    }
  }
}

// ------------------------------------------------------ V-MASK -------------------------------------------------------

let maskPoint = -1,
  maskStatus = null,
  maskUniqueSymbols,
  maskCharOnPoint,
  maskPressedKey,
  maskWrongCharIndex,
  checkMax = false,
  max,
  maskGeneralState;

export const mask = {
  update(el, binding) {
    const element = el.tagName === "INPUT" ? el : el.querySelector("input");
    const event = new Event("input", { bubbles: true });

    if (element.value) {
      // this condition is just for manipulating for the Sheba number
      if (element.id === "sheba") {
        if (element.value.slice(0, 2) !== "IR") {
          if (
            element.value.length < 2 ||
            /[\d۰-۹]/.test(element.value.slice(0, 2))
          ) {
            element.value = "IR" + element.value;
          } else {
            element.value = "IR" + element.value.slice(2);
          }
        }
      }
      let symbols = binding.value.replace(/[#WA]/gi, "");
      maskUniqueSymbols = Array.from(symbols).filter((element, index) => {
        return symbols.indexOf(element) === index;
      });
      maskUniqueSymbols = maskUniqueSymbols.toString();
      maskUniqueSymbols = maskUniqueSymbols.replace(/,/g, "");
      if (symbols.includes(",")) {
        maskUniqueSymbols = maskUniqueSymbols + ",";
      }
      //maskUniqueSymbols includes the allowed symbols which should come through the numbers/alphabet words
      maskUniqueSymbols = maskUniqueSymbols.replace(
        new RegExp(`(?=(.|-))`, "g"),
        "\\"
      );
      const elementValue = element.value.toString();
      const convertedValue = maskSetNumbers(
        maskToEnNumber(
          maskCheckInput(elementValue, maskUniqueSymbols, binding.value)
        ),
        binding.value
      );
      // this function will adjust the place of caret after adding or removing a word
      maskSetCaret(element, binding.value, convertedValue);
      element.value = convertedValue;
      // if a bunch of characters gets copy and paste, those characters will settle on the template then gets checked for the max length to get extra characters deleted.
      if (checkMax) {
        setTimeout(() => {
          element.value = element.value.slice(0, max);
          element.dispatchEvent(event);
        }, 1);
      }
      element.dispatchEvent(event);
    }
  },
};

//this function checks which characters are allowed to display
function maskCheckInput(char, sign, template) {
  let allowedChars = "";
  if (/#/.test(template)) {
    allowedChars = "\\d۰-۹";
  }
  if (/W/i.test(template)) {
    allowedChars = allowedChars + "\\a-zA-Z";
  }
  if (/A/i.test(template)) {
    allowedChars = "\\d۰-۹\\a-zA-Z";
  }
  maskGeneralState = new RegExp(`[^${allowedChars}${sign}]`);
  // remove the wrong character. For instance, you specify the template of ###-### which means the alphabet must not be displayed.
  let wrongChar = char.match(maskGeneralState);
  if (wrongChar) {
    maskWrongCharIndex = wrongChar.index;
    char =
      char.slice(0, maskWrongCharIndex) + char.slice(maskWrongCharIndex + 1);
  }
  return char;
}

// this function transforms the farsi numbers to english ones
function maskToEnNumber(number) {
  const toEn = {
    "۰": "0",
    "۱": "1",
    "۲": "2",
    "۳": "3",
    "۴": "4",
    "۵": "5",
    "۶": "6",
    "۷": "7",
    "۸": "8",
    "۹": "9",
  };
  const str = String(number);
  const enStr = Array.from(str).reduce(
    (prev, curr) => `${prev}${toEn[curr] || curr}`,
    ""
  );
  return enStr;
}

//This functions not only change the inputted characters to the requested template, but also remove the wrong characters
function maskSetNumbers(char, template) {
  let i = 0;
  let obj = {};
  const maxLength = template.length;
  max = maxLength;
  while (i < maxLength) {
    if (template[i] !== "#" && template[i] !== "W" && template[i] !== "A") {
      obj[i] = template[i];
    }
    i++;
  }
  const keys = Object.keys(obj);
  let j = 0;
  char = char.replace(/[^\w۰-۹]/g, "");
  for (let i = 0; i < char.length + 1; i++) {
    if (+keys[j] > char.length) {
      break;
    }
    if (+keys[j] < i + 1) {
      if (char[i] !== obj[keys[j]]) {
        char = char.slice(0, keys[j]) + obj[keys[j]] + char.slice(keys[j]);
      }
      j++;
    }
  }
  //delete the symbol characters which have come in first and all words are also deleted
  const preSigns = template.search(/[#AW]/i);
  if (char === template.slice(0, preSigns)) {
    char = "";
  }

  // check to delete wrong characters rather than requested template
  for (let i = 0; i < template.length + 1; i++) {
    if (i >= char.length) {
      break;
    }
    if (template[i] === "#") {
      if (!new RegExp(char[i]).test(maskUniqueSymbols)) {
        if (!/[\d۰-۹]/.test(char[i])) {
          maskWrongCharIndex = i;
          char = char.slice(0, i) + char.slice(i + 1);
          break;
        }
      }
    }
    if (template[i] === "W" || template[i] === "w") {
      if (!new RegExp(char[i]).test(maskUniqueSymbols)) {
        if (!/[a-zA-Z]/.test(char[i])) {
          maskWrongCharIndex = i;
          char = char.slice(0, i) + char.slice(i + 1);
          break;
        }
      }
    }
    if (template[i] === "A" || template[i] === "a") {
      if (!new RegExp(char[i]).test(maskUniqueSymbols)) {
        if (!/[\d۰-۹a-zA-Z]/.test(char[i])) {
          maskWrongCharIndex = i;
          char = char.slice(0, i) + char.slice(i + 1);
          break;
        }
      }
    }
  }
  if (char.length === maxLength + 1) {
    char = char.slice(0, maxLength);
  } else if (char.length > maxLength) {
    checkMax = true;
  }
  return char;
}

//put the caret on the right place in the input when add or delete a character
function maskSetCaret(element, template, input) {
  element.addEventListener("keydown", (e) => {
    maskPoint = e.target.selectionStart; //the index of caret
    maskPressedKey = e.keyCode; //keyCode of button which gets clicked
  });
  maskCharOnPoint = element.value.charAt(maskPoint); //specify the character of point

  if (maskWrongCharIndex) {
    //keep the caret when an invalid character gets clicked.
    element.setSelectionRange(maskWrongCharIndex, maskWrongCharIndex);
    setTimeout(() => {
      maskWrongCharIndex = null;
    }, 1);
  } else if (maskPoint === -1) {
    element.setSelectionRange(input.length, input.length);
  } else if (/\b8\b|\b46\b/g.test(maskPressedKey)) {
    element.setSelectionRange(maskPoint - 1, maskPoint - 1);
  } else {
    if (!maskStatus) {
      if (/[\w۰-۹]/g.test(maskCharOnPoint)) {
        maskStatus = 1;
        element.setSelectionRange(maskPoint + 1, maskPoint + 1);
      } else {
        maskStatus = 2;
        element.setSelectionRange(maskPoint, maskPoint);
      }
      setTimeout(() => {
        maskStatus = null;
      }, 1);
    } else {
      if (maskStatus === 1) {
        maskStatus = null;
        if (
          /[\w۰-۹]/.test(element.value.charAt(maskPoint)) &&
          /[\w۰-۹]/.test(element.value.charAt(maskPoint + 1))
        ) {
          element.setSelectionRange(maskPoint + 1, maskPoint + 1);
        } else {
          let specificCondition = 0;
          if (/[^\w۰-۹]/.test(element.value.charAt(maskPoint))) {
            specificCondition = 1;
          }
          let i = 0;
          let pointCorrectPlace = 0;
          do {
            ++i;
            pointCorrectPlace = maskPoint + i;
          } while (/[^\w۰-۹]/.test(element.value.charAt(pointCorrectPlace)));
          element.setSelectionRange(
            pointCorrectPlace + specificCondition,
            pointCorrectPlace + specificCondition
          );
        }
      } else {
        maskStatus = null;
        element.setSelectionRange(maskPoint, maskPoint);
      }
    }
  }
}

// ----------------------------------------------------- FILTER-SEPARATOR ---------------------------------------------------
let generalStateFilter,
  digitsAfterDotFilter = null,
  separatorAfterEachFilter,
  separatorSignFilter = null,
  maxDigitsFilter,
  dotFilter,
  zeroPermissionFilter = false;
export const separatorFilter = (val, temp) => {
  if (temp) {
    if (temp.separator) {
      if (temp.separator.sign) {
        separatorSignFilter = temp.separator.sign;
        separatorSignFilter =
          separatorSignFilter === "." ? "," : separatorSignFilter;
      } else {
        separatorSignFilter = ",";
      }
      if (temp.separator.afterEach) {
        separatorAfterEachFilter = temp.separator.afterEach;
      } else {
        separatorAfterEachFilter = "3";
      }
    } else {
      separatorSignFilter = "";
    }
    if (temp.dot) {
      dotFilter = ".";
      if (temp.dot.afterDot) {
        digitsAfterDotFilter = temp.dot.afterDot;
      } else {
        digitsAfterDotFilter = null;
      }
    } else {
      dotFilter = "";
    }
    maxDigitsFilter = temp.maxDigits ? Number(temp.maxDigits) : null;
    zeroPermissionFilter = temp.zeroAtBegin ? true : false;
  }
  if (val) {
    const elementValue = val.toString();
    const convertedValue = setSeparatorFilter(
      toEnNumberFilter(
        checkInputFilter(elementValue, dotFilter, separatorSignFilter)
      )
    );

    return convertedValue;
  }
};
function checkInputFilter(char, dot = "", sign) {
  if (sign === null) {
    sign = "";
  }
  generalStateFilter = new RegExp(`[^\\d۰-۹${dot}${sign}]`);
  // when the input starts with . it turns to 0.
  if (!".".match(generalStateFilter) && char.includes(".")) {
    if (/^\./g.test(char)) {
      char = "0".concat(char);
    }
  }
  // Do not let more than one Zero comes at the beginning, but 0.
  if (/^0/g.test(char) && !zeroPermissionFilter) {
    if (char.length >= 2 && !/^0\./g.test(char)) {
      const x = char.search(/[^0,]/);
      char = char.slice(x, char.length);
    }
  }

  // remove the wrong character such as alphabet
  char = char.replace(new RegExp(`[^\\d۰-۹${dot}${sign}]`, "g"), "");
  return char;
}

function toEnNumberFilter(number) {
  const toEn = {
    "۰": "0",
    "۱": "1",
    "۲": "2",
    "۳": "3",
    "۴": "4",
    "۵": "5",
    "۶": "6",
    "۷": "7",
    "۸": "8",
    "۹": "9",
  };
  const str = String(number);
  const enStr = Array.from(str).reduce(
    (prev, curr) => `${prev}${toEn[curr] || curr}`,
    ""
  );
  return enStr;
}

function setSeparatorFilter(num) {
  let numbersAfterDot = "";
  if (num.includes(".")) {
    if (num.match(/\./g).length > 1) {
      if (
        maxDigitsFilter &&
        num.match(/\d/g).length > maxDigitsFilter &&
        /\./.test(num.slice(maxDigitsFilter))
      ) {
        let counter = -1;
        let digitsCount;
        let validNumbers;
        do {
          counter += 1;
          validNumbers = num.slice(0, maxDigitsFilter + counter);
          digitsCount = validNumbers.match(/\d/g).length;
        } while (digitsCount < maxDigitsFilter);
        num = validNumbers;
        if (num.match(/\./g).length > 1) {
          do {
            num = num.replace(/\./, "");
          } while (num.match(/\./g).length !== 1);
        }
      } else {
        do {
          num = num.replace(/\./, "");
        } while (num.match(/\./g).length !== 1);
      }
    }
    //specify the numbers of before and after the dot to get them merged finally
    num = num.replace(new RegExp(separatorSignFilter, "g"), "");

    const dotIndex = num.indexOf(".");
    const digitsAfterDotToCheckToFixed = num.slice(dotIndex + 1);
    if (digitsAfterDotFilter) {
      if (digitsAfterDotToCheckToFixed.length > digitsAfterDotFilter) {
        let numberOfZeros = "",
          counter = 0,
          permission = false;

        if (zeroPermissionFilter && !/^0\./.test(num) && num[0] === "0") {
          numberOfZeros = num.search(/[^0]/);
          permission = true;
        }
        // The below 2 line codes are due to toFixed not only might change the last digit for rounding, but also for more than 18 digits for toFixed doesn't work properly.
        const digitsBeforeDot = num.slice(0, dotIndex + 1);
        num = digitsBeforeDot.concat(
          num.slice(
            digitsBeforeDot.length,
            digitsBeforeDot.length + Number(digitsAfterDotFilter)
          )
        );
        // num = Number(num).toFixed(digitsAfterDotFilter);
        if (permission === true) {
          do {
            counter++;
            num = "0" + num;
          } while (numberOfZeros > counter);
        }
      }
    }
    // check and set if there is any max digits limitation
    if (maxDigitsFilter) {
      if (num.length > maxDigitsFilter) {
        if (num.indexOf(".") < maxDigitsFilter) {
          num = num.slice(0, maxDigitsFilter + 1);
        } else num = num.slice(0, maxDigitsFilter);
      }
    }
    numbersAfterDot = num.slice(dotIndex);
    num = num.slice(0, dotIndex);
  } else {
    if (maxDigitsFilter) {
      num = num.replace(new RegExp(separatorSignFilter, "g"), "");
      if (num.length > maxDigitsFilter) {
        num = num.slice(0, maxDigitsFilter);
      }
    }
  }

  num = num.replace(new RegExp(separatorSignFilter, "g"), "");
  if (separatorAfterEachFilter) {
    return num
      .replace(
        new RegExp(`\\B(?=(\\d{${separatorAfterEachFilter}})+(?!\\d))`, "g"),
        separatorSignFilter
      )
      .concat(numbersAfterDot);
  } else {
    //when no separator is chosen
    return num.concat(numbersAfterDot);
  }
}

// ----------------------------------------------------- FILTER-MASK ---------------------------------------------------
let maskUniqueSymbolsFilter, maskWrongCharIndexFilter, maskGeneralStateFilter;
export const maskFilter = (val, temp) => {
  if (val) {
    let symbols = temp.replace(/[#WA]/gi, "");
    maskUniqueSymbolsFilter = Array.from(symbols).filter((item, index) => {
      return symbols.indexOf(item) === index;
    });
    maskUniqueSymbolsFilter = maskUniqueSymbolsFilter.toString();
    maskUniqueSymbolsFilter = maskUniqueSymbolsFilter.replace(/,/g, "");
    if (symbols.includes(",")) {
      maskUniqueSymbolsFilter = maskUniqueSymbolsFilter + ",";
    }
    //maskUniqueSymbols includes the allowed symbols which should come through the numbers/alphabet words
    maskUniqueSymbolsFilter = maskUniqueSymbolsFilter.replace(
      new RegExp(`(?=(.|-))`, "g"),
      "\\"
    );
    const elementValue = val.toString();
    const convertedValue = maskSetNumbersFilter(
      maskToEnNumberFilter(
        maskCheckInputFilter(elementValue, maskUniqueSymbolsFilter, temp)
      ),
      temp
    );
    val = convertedValue;
    return val;
  }
};

//this function checks which characters are allowed to display
function maskCheckInputFilter(char, sign, template) {
  let allowedChars = "";
  if (/#/.test(template)) {
    allowedChars = "\\d۰-۹";
  }
  if (/W/i.test(template)) {
    allowedChars = allowedChars + "\\a-zA-Z";
  }
  if (/A/i.test(template)) {
    allowedChars = "\\d۰-۹\\a-zA-Z";
  }
  maskGeneralStateFilter = new RegExp(`[^${allowedChars}${sign}]`);

  // remove the wrong character. For instance, you specify the template of ###-### which means the alphabet must not be displayed.
  let wrongChar = char.match(maskGeneralStateFilter);
  if (wrongChar) {
    maskWrongCharIndexFilter = wrongChar.index;
    char =
      char.slice(0, maskWrongCharIndexFilter) +
      char.slice(maskWrongCharIndexFilter + 1);
    return maskCheckInputFilter(char, sign, template);
  }

  return char;
}

// this function transforms the farsi numbers to english ones
function maskToEnNumberFilter(number) {
  const toEn = {
    "۰": "0",
    "۱": "1",
    "۲": "2",
    "۳": "3",
    "۴": "4",
    "۵": "5",
    "۶": "6",
    "۷": "7",
    "۸": "8",
    "۹": "9",
  };
  const str = String(number);
  const enStr = Array.from(str).reduce(
    (prev, curr) => `${prev}${toEn[curr] || curr}`,
    ""
  );
  return enStr;
}

//This functions not only change the inputted characters to the requested template, but also remove the wrong characters
function maskSetNumbersFilter(char, template) {
  let i = 0;
  let obj = {};
  const maxLength = template.length;
  while (i < maxLength) {
    if (template[i] !== "#" && template[i] !== "W" && template[i] !== "A") {
      obj[i] = template[i];
    }
    i++;
  }
  const keys = Object.keys(obj);
  let j = 0;
  char = char.replace(/[^\w۰-۹]/g, "");
  for (let i = 0; i < char.length + 1; i++) {
    if (+keys[j] > char.length) {
      break;
    }
    if (+keys[j] < i + 1) {
      if (char[i] !== obj[keys[j]]) {
        char = char.slice(0, keys[j]) + obj[keys[j]] + char.slice(keys[j]);
      }
      j++;
    }
  }
  //delete the symbol characters which have come in first and all words are also deleted
  const preSigns = template.search(/[#AW]/i);
  if (char === template.slice(0, preSigns)) {
    char = "";
  }

  // check to delete wrong characters rather than requested template
  for (let i = 0; i < template.length + 1; i++) {
    if (i >= char.length) {
      break;
    }
    if (template[i] === "#") {
      if (!new RegExp(char[i]).test(maskUniqueSymbolsFilter)) {
        if (!/[\d۰-۹]/.test(char[i])) {
          maskWrongCharIndexFilter = i;
          char = char.slice(0, i) + char.slice(i + 1);
          return maskSetNumbersFilter(char, template);
        }
      }
    }
    if (template[i] === "W" || template[i] === "w") {
      if (!new RegExp(char[i]).test(maskUniqueSymbolsFilter)) {
        if (!/[a-zA-Z]/.test(char[i])) {
          maskWrongCharIndexFilter = i;
          char = char.slice(0, i) + char.slice(i + 1);
          return maskSetNumbersFilter(char, template);
        }
      }
    }
    if (template[i] === "A" || template[i] === "a") {
      if (!new RegExp(char[i]).test(maskUniqueSymbolsFilter)) {
        if (!/[\d۰-۹a-zA-Z]/.test(char[i])) {
          maskWrongCharIndexFilter = i;
          char = char.slice(0, i) + char.slice(i + 1);
          return maskSetNumbersFilter(char, template);
        }
      }
    }
  }
  char = char.slice(0, maxLength);
  return char;
}
