<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8047 3.13798L12.8621 2.19532C12.6014 1.93465 12.1794 1.93465 11.9194 2.19532L4.86208 9.25265C4.73675 9.37732 4.66675 9.54732 4.66675 9.72398V11.3333H6.27608C6.45275 11.3333 6.62275 11.2633 6.74741 11.138L13.8047 4.08065C14.0654 3.82065 14.0654 3.39865 13.8047 3.13798V3.13798Z"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6066 5.27331L10.7266 3.39331"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 7.33333V12.6667C14 13.4033 13.4033 14 12.6667 14H3.33333C2.59667 14 2 13.4033 2 12.6667V3.33333C2 2.59667 2.59667 2 3.33333 2H8.66667"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
