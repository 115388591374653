import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/auth",
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    meta: { transitionName: "slide" },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/Auth/index"),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("access_token"))
        next({
          path: "/panel",
        });
      else next({});
    },
    children: [
      {
        path: "register",
        meta: { transitionName: "slide" },
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/Auth/Register/index"
          ),
        children: [
          {
            path: "/",
            name: "registerForm",
            meta: { transitionName: "slide", hasAuthButton: true },
            component: () =>
              import(
                /* webpackChunkName: "register-form" */ "../components/Auth/Register/RegisterForm/index"
              ),
          },
          {
            path: "register-verification",
            name: "registerVerification",
            meta: { transitionName: "slide" },
            component: () =>
              import(
                /* webpackChunkName: "register-verification" */ "../components/Auth/Register/RegisterVerification/index"
              ),
          },
          {
            path: "succeed-registration",
            name: "succeedRegistration",
            meta: { transitionName: "slide" },
            component: () =>
              import(
                /* webpackChunkName: "succeed-registration" */ "../components/Auth/Register/SucceedRegistration/index"
              ),
          },
        ],
      },
      {
        path: "login",
        meta: { transitionName: "slide" },
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Auth/Login/index"),
        children: [
          {
            path: "/",
            name: "loginForm",
            meta: { transitionName: "slide", hasAuthButton: true },
            component: () =>
              import(
                /* webpackChunkName: "login-form" */ "../components/Auth/Login/LoginForm/index"
              ),
          },
          {
            path: "password-recovery",
            name: "passwordRecovery",
            meta: { transitionName: "slide" },
            component: () =>
              import(
                /* webpackChunkName: "password-recovery" */ "../components/Auth/Login/PasswordRecovery/index"
              ),
          },
          {
            path: "reset-password-otp",
            name: "resetPasswordOtp",
            meta: { transitionName: "slide" },
            component: () =>
              import(
                /* webpackChunkName: "reset-password-otp" */ "../components/Auth/Login/ResetPasswordOtp/index"
              ),
          },
          {
            path: "reset-password",
            name: "resetPassword",
            meta: { transitionName: "slide" },
            component: () =>
              import(
                /* webpackChunkName: "reset-password" */ "../components/Auth/Login/ResetPassword/index"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/panel",
    redirect: "/panel/dashboard",
    meta: { transitionName: "slide" },
    component: () =>
      import(
        /* webpackChunkName: "panel-layout" */ "../views/Panel/Layout/index"
      ),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("access_token")) next();
      else next({ name: "loginForm" });
    },
    children: [
      {
        path: "dashboard",
        meta: {
          transitionName: "slide",
        },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/Panel/Dashboard/index"
          ),
        children: [
          {
            path: "/",
            name: "dashboardWidgets",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.dashboard",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "dashboard-widgets" */ "../components/Dashboard/DashboardWidgets/index"
              ),
          },
          {
            path: "withdrawal",
            name: "withdrawal",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.withdrawal",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "withdrawal" */ "../components/Dashboard/Withdrawal/WithdrawalForm/index"
              ),
          },
          {
            path: ":trxId/withdrawal-report",
            name: "withdrawalReport",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.withdrawal",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "withdrawal" */ "../components/Dashboard/Withdrawal/WithdrawReport/index"
              ),
          },
        ],
      },
      {
        path: "transactions",
        meta: {
          transitionName: "slide",
        },
        component: () =>
          import(
            /* webpackChunkName: "transactions" */ "../views/Panel/Transactions/index"
          ),
        children: [
          {
            path: "/",
            name: "transactionsList",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.transactions",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "transactions-list" */ "../components/Transactions/TransactionsList/index"
              ),
          },
          {
            path: "deposit-details/:id",
            name: "depositTransactionDetails",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.cashInDetails",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "deposit-transaction-details" */ "../components/Transactions/DepositTransactions/DepositTransactionDetails/index"
              ),
          },
          {
            path: "withdrawal-details/:id",
            name: "withdrawalTransactionDetails",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.cashOutDetails",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "withdrawal-transaction-details" */ "../components/Transactions/WithdrawalTransactions/WithdrawalTransactionDetails/index"
              ),
          },
          {
            path: "refund-details/:id",
            name: "refundTransactionDetails",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.refundDetails",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "refund-transaction-details" */ "../components/Transactions/RefundTransactions/RefundTransactionDetails/index"
              ),
          },
        ],
      },
      {
        path: "businesses",
        meta: {
          transitionName: "slide",
        },
        component: () =>
          import(
            /* webpackChunkName: "businesses" */ "../views/Panel/Businesses/index"
          ),
        children: [
          {
            path: "/",
            name: "businessesList",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.businesses",
            },
            component: () =>
              import(
                /* webpackChunkName: "businesses-list" */ "../components/Businesses/BusinessesList/index"
              ),
          },
          {
            path: "create",
            name: "createBusiness",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.createBusiness",
            },
            component: () =>
              import(
                /* webpackChunkName: "create-business" */ "../components/Businesses/CreateBusiness/index"
              ),
          },
          {
            path: ":id/details",
            name: "businessDetails",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.businessDetails",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "business-details" */ "../components/Businesses/BusinessDetails/index"
              ),
          },
          {
            path: ":businessId/create-gateway",
            name: "createGateway",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.createGateway",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "create-gateway" */ "../components/Businesses/CreateGateway/index"
              ),
          },
          {
            path: ":businessId/create-pos-gateway",
            name: "createPosGateway",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.createPosGateway",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "create-pos-gateway" */ "../components/Businesses/CreatePosGateway/index"
              ),
          },
          {
            path: "pos-gateway-settings/:id",
            name: "posGatewaySettings",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.posGatewaySettings",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "pos-gateway-settings" */ "../components/Businesses/PosGatewaySettings/index"
              ),
          },
          {
            path: ":id/create-pos-gateway-report",
            name: "createPosGatewayReport",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.createGateway",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "create-pos-gateway-report" */ "../components/Businesses/CreatePosGatewayReport/index"
              ),
          },
          {
            path: ":businessId/create-web-gateway",
            name: "createWebGateway",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.createWebGateway",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "create-web-gateway" */ "../components/Businesses/CreateWebGateway/index"
              ),
          },
          {
            path: "web-gateway-settings/:id",
            name: "webGatewaySettings",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.webGatewaySettings",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "web-gateway-settings" */ "../components/Businesses/WebGatewaySettings/index"
              ),
          },
          {
            path: ":id/create-web-gateway-report",
            name: "createWebGatewayReport",
            meta: {
              transitionName: "slide",
              appBarTitle: "panel.layout.appBar.createGateway",
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "create-web-gateway-report" */ "../components/Businesses/CreateWebGatewayReport/index"
              ),
          },
        ],
      },
      {
        path: "user-profile",
        name: "userProfile",
        meta: {
          transitionName: "slide",
          appBarTitle: "panel.layout.appBar.settings",
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "user-profile" */ "../views/Panel/UserProfile/index"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
