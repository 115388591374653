<script>
export default {
  props: ["clr", "strokeWidth"],
};
</script>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 13.3355C9.95426 13.3357 11.7522 12.2674 12.6866 10.551"
      :stroke="clr"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3356 7.99986C13.3356 5.05312 10.9467 2.66431 8 2.66431"
      :stroke="clr"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00009 2.66431C6.04584 2.66414 4.24786 3.73239 3.31348 5.4488"
      :stroke="clr"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.66443 8C2.66443 10.9467 5.05324 13.3356 7.99998 13.3356"
      :stroke="clr"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.31348 5.4489C4.47979 3.30555 6.95153 2.22655 9.31625 2.82847C11.681 3.43039 13.336 5.55983 13.3357 7.99996"
      :stroke="clr"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.66443 8C2.66406 10.4401 4.31911 12.5696 6.68383 13.1715C9.04855 13.7734 11.5203 12.6944 12.6866 10.5511"
      :stroke="clr"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.36363 5.44889H3.00598V3.09058"
      :stroke="clr"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6365 10.551H12.9941V12.9093"
      :stroke="clr"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
