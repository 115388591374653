import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import drawer from "./modules/navigationDrawer";
import userInfo from "./modules/userInfo";

export default new Vuex.Store({
  modules: {
    drawer,
    userInfo,
  },
});
