<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.3334 18.0834H28.4167"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.4167 28.4167H41.3334"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.4167 38.75H41.3334"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3333 12.3432V50.375C10.3333 52.2944 12.3535 53.5448 14.0714 52.6845L16.0632 51.6873C16.7917 51.3231 17.6468 51.3231 18.3727 51.6873L22.9529 53.9762C23.6788 54.3404 24.5365 54.3404 25.2624 53.9762L29.8427 51.6873C30.5686 51.3231 31.4263 51.3231 32.1522 51.6873L36.7324 53.9762C37.4609 54.3404 38.316 54.3404 39.0445 53.9762L43.6196 51.6873C44.3481 51.3231 45.2032 51.3231 45.9317 51.6873L47.926 52.6845C49.6465 53.5448 51.6667 52.2944 51.6667 50.375V12.3432C51.6667 9.80633 49.6103 7.75 47.0735 7.75H14.9265C12.3897 7.75 10.3333 9.80633 10.3333 12.3432Z"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.3412 17.7604C20.1655 17.7604 20.0182 17.9051 20.0208 18.0834C20.0208 18.2616 20.1655 18.4063 20.3437 18.4063C20.522 18.4063 20.6667 18.2616 20.6667 18.0834C20.6667 17.9051 20.522 17.7604 20.3412 17.7604"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.3412 28.0938C20.1629 28.0938 20.0182 28.2384 20.0208 28.4167C20.0208 28.5949 20.1655 28.7396 20.3437 28.7396C20.522 28.7396 20.6667 28.5949 20.6667 28.4167C20.6667 28.2384 20.522 28.0938 20.3412 28.0938"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.3412 38.4271C20.1629 38.4271 20.0182 38.5717 20.0208 38.75C20.0208 38.9282 20.1655 39.0729 20.3437 39.0729C20.522 39.0729 20.6667 38.9282 20.6667 38.75C20.6667 38.5717 20.522 38.4271 20.3412 38.4271"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
