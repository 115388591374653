<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.22 10.89V20.89"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.78 10.89V20.89"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.222 20.889V16.445C14.222 15.218 13.227 14.223 12 14.223C10.773 14.223 9.778 15.218 9.778 16.445V20.889"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 20.89H22"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.301 10.889C2.603 10.877 1.496 9.196 2.232 7.747L3.947 4.37C4.338 3.6 5.16 3.111 6.062 3.111H17.938C18.84 3.111 19.662 3.6 20.053 4.37L21.769 7.747C22.505 9.196 21.398 10.877 19.7 10.889C18.283 10.889 17.134 9.921 17.134 8.726V8.678C17.134 9.899 15.985 10.889 14.568 10.889C13.151 10.889 12.002 9.899 12.002 8.678C12.002 9.899 10.853 10.889 9.436 10.889C8.019 10.889 6.87 9.899 6.87 8.678V8.726C6.867 9.921 5.718 10.889 4.301 10.889Z"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
