<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0573 12.1102L12.4999 15.6677L8.94238 12.1102"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 3.99664V14.6681"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.5037 16.4457C20.5037 18.4105 18.911 20.0032 16.9463 20.0032H8.05455C6.08981 20.0032 4.49707 18.4105 4.49707 16.4457"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
