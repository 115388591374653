<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0833 17.776V6.87773C10.0833 6.24523 9.75792 5.65765 9.22167 5.32307L5.555 3.0314C4.334 2.26873 2.75 3.14598 2.75 4.58607V15.4834C2.75 16.1159 3.07542 16.7035 3.61167 17.0381L7.27833 19.3297C8.49933 20.0933 10.0833 19.2151 10.0833 17.776Z"
      stroke="#2A2C30"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.75 10.0833H19.25"
      stroke="#2A2C30"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4167 11.9167L19.2501 10.0833L17.4167 8.25"
      stroke="#2A2C30"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0833 17.4167H13.7499C14.7628 17.4167 15.5833 16.5963 15.5833 15.5834V14.6667"
      stroke="#2A2C30"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.5833 5.5V4.58333C15.5833 3.57042 14.7628 2.75 13.7499 2.75H4.58325"
      stroke="#2A2C30"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
