<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10.5"
      cy="10"
      r="9"
      fill="#8A6DDE"
      stroke="#8A6DDE"
      stroke-width="2"
    />
    <circle cx="10.5" cy="10" r="3.5" fill="#F8F8F6" stroke="#F8F8F6" />
  </svg>
</template>
