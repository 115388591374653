<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99942 5.33332C7.90742 5.33332 7.83275 5.40799 7.83342 5.49999C7.83342 5.59199 7.90808 5.66666 8.00008 5.66666C8.09208 5.66666 8.16675 5.59199 8.16675 5.49999C8.16675 5.40799 8.09208 5.33332 7.99942 5.33332"
      stroke="#3889D0"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z"
      stroke="#3889D0"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00008 8V11.3333"
      stroke="#3889D0"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
