<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="4" fill="#8A6DDE" />
    <path
      d="M14 7.25L8.5 12.75L6 10.25"
      stroke="#F8F8F6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
