<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="62"
    height="63"
    viewBox="0 0 62 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9017 28.6326V54.4659"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M51.0983 28.6326V54.4659"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.7402 54.4632V42.9828C36.7402 39.8131 34.1698 37.2427 31 37.2427C27.8303 37.2427 25.2598 39.8131 25.2598 42.9828V54.4632"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.16667 54.4657H56.8333"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1109 28.63C6.72442 28.599 3.86467 24.2564 5.766 20.5132L10.1964 11.7893C11.2065 9.80012 13.33 8.53687 15.6602 8.53687H46.3398C48.67 8.53687 50.7935 9.80012 51.8036 11.7893L56.2366 20.5132C58.1379 24.2564 55.2782 28.599 50.8917 28.63C47.2311 28.63 44.2628 26.1294 44.2628 23.0423V22.9183C44.2628 26.0725 41.2946 28.63 37.634 28.63C33.9734 28.63 31.0052 26.0725 31.0052 22.9183C31.0052 26.0725 28.0369 28.63 24.3763 28.63C20.7157 28.63 17.7475 26.0725 17.7475 22.9183V23.0423C17.7397 26.1294 14.7715 28.63 11.1109 28.63Z"
      stroke="#CCCED0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
