<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3362 11.5018L18 6.16555L12.6638 11.5018"
      :stroke="clr"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 7.49542V23.5026"
      :stroke="clr"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.005 24.6686C30.005 27.6157 27.6159 30.0048 24.6688 30.0048H11.3312C8.38411 30.0048 5.99501 27.6157 5.99501 24.6686"
      :stroke="clr"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
