import eyeShow from "@/assets/AppIcons/eyeShow";
import eyeHidden from "@/assets/AppIcons/eyeHidden";
import checkMarkCircle from "@/assets/AppIcons/checkMarkCircle";
import groupElements from "@/assets/AppIcons/groupElements";
import item from "@/assets/AppIcons/item";
import itemInPage from "@/assets/AppIcons/itemPage";
import info from "@/assets/AppIcons/info";
import shareArrow from "@/assets/AppIcons/shareArrow";
import edit from "@/assets/AppIcons/edit";
import close from "@/assets/AppIcons/close";
import arrow from "@/assets/AppIcons/arrow";
import hourGlass from "@/assets/AppIcons/hourGlass";
import settings from "@/assets/AppIcons/settings";
import shop from "@/assets/AppIcons/shop";
import shopInPage from "@/assets/AppIcons/shopPage";
import add from "@/assets/AppIcons/add";
import browser from "@/assets/AppIcons/browser";
import check from "@/assets/AppIcons/check";
import uploadArrow from "@/assets/AppIcons/uploadArrow";
import emptyCircle from "@/assets/AppIcons/emptyCircle";
import activeCircle from "@/assets/AppIcons/activeCircle";
import emptyCheckbox from "@/assets/AppIcons/emptyCheckbox";
import activeCheckbox from "@/assets/AppIcons/activeCheckbox";
import pluginSquare from "@/assets/AppIcons/pluginSquare";
import warning from "@/assets/AppIcons/warning";
import pos from "@/assets/AppIcons/pos";
import successPhase from "@/assets/AppIcons/successPhase";
import pendingPhase from "@/assets/AppIcons/pendingPhase";
import googlePlay from "@/assets/AppIcons/googlePlay";
import qrCode from "@/assets/AppIcons/qrCode";
import logout from "@/assets/AppIcons/logout";
import arrowDown from "@/assets/AppIcons/arrowDown";
import reload from "@/assets/AppIcons/reload";
import filledCheckMark from "@/assets/AppIcons/filledCheckMark";
import checkMarkDone from "@/assets/AppIcons/checkMarkDone";
import wallet from "@/assets/AppIcons/wallet";
import downloadArrow from "@/assets/AppIcons/downloadArrow";
import bars from "@/assets/AppIcons/bars";
import googlePlayQRCode from "@/assets/AppIcons/googlePlayQRCode";
import copyToClipboard from "@/assets/AppIcons/copyToClipboard";
import successIcon from "@/assets/AppIcons/successIcon";

const CustomIcons = {
  copyToClipboard: {
    component: copyToClipboard,
  },
  successIcon: {
    component: successIcon,
  },
  eyeShow: {
    component: eyeShow,
  },
  eyeHidden: {
    component: eyeHidden,
  },
  checkMarkCircle: {
    component: checkMarkCircle,
  },
  groupElements: {
    component: groupElements,
  },
  item: {
    component: item,
  },
  itemInPage: {
    component: itemInPage,
  },
  info: {
    component: info,
  },
  shareArrow: {
    component: shareArrow,
  },
  edit: {
    component: edit,
  },
  close: {
    component: close,
  },
  arrow: {
    component: arrow,
  },
  hourGlass: {
    component: hourGlass,
  },
  settings: {
    component: settings,
  },
  shop: {
    component: shop,
  },
  shopInPage: {
    component: shopInPage,
  },
  add: {
    component: add,
  },
  browser: {
    component: browser,
  },
  check: {
    component: check,
  },
  uploadArrow: {
    component: uploadArrow,
  },
  emptyCircle: {
    component: emptyCircle,
  },
  activeCircle: {
    component: activeCircle,
  },
  emptyCheckbox: {
    component: emptyCheckbox,
  },
  activeCheckbox: {
    component: activeCheckbox,
  },
  pluginSquare: {
    component: pluginSquare,
  },
  warning: {
    component: warning,
  },
  pos: {
    component: pos,
  },
  successPhase: {
    component: successPhase,
  },
  pendingPhase: {
    component: pendingPhase,
  },
  googlePlay: {
    component: googlePlay,
  },
  qrCode: {
    component: qrCode,
  },
  logout: {
    component: logout,
  },
  arrowDown: {
    component: arrowDown,
  },
  reload: {
    component: reload,
  },
  filledCheckMark: {
    component: filledCheckMark,
  },
  checkMarkDone: {
    component: checkMarkDone,
  },
  wallet: {
    component: wallet,
  },
  downloadArrow: {
    component: downloadArrow,
  },
  bars: {
    component: bars,
  },
  googlePlayQRCode: {
    component: googlePlayQRCode,
  },
};

export default CustomIcons;
