<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.16667 11H4.58333C3.57042 11 2.75 10.1796 2.75 9.16667V4.58333C2.75 3.57042 3.57042 2.75 4.58333 2.75H9.16667C10.1796 2.75 11 3.57042 11 4.58333V9.16667C11 10.1796 10.1796 11 9.16667 11Z"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9583 18.3333H14.2083C13.4493 18.3333 12.8333 17.7173 12.8333 16.9583V14.2083C12.8333 13.4493 13.4493 12.8333 14.2083 12.8333H16.9583C17.7173 12.8333 18.3333 13.4493 18.3333 14.2083V16.9583C18.3333 17.7173 17.7173 18.3333 16.9583 18.3333Z"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.875 10.0833H15.125C14.366 10.0833 13.75 9.46734 13.75 8.70834V5.95834C13.75 5.19934 14.366 4.58334 15.125 4.58334H17.875C18.634 4.58334 19.25 5.19934 19.25 5.95834V8.70834C19.25 9.46734 18.634 10.0833 17.875 10.0833Z"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.70825 19.25H5.95825C5.19925 19.25 4.58325 18.634 4.58325 17.875V15.125C4.58325 14.366 5.19925 13.75 5.95825 13.75H8.70825C9.46725 13.75 10.0833 14.366 10.0833 15.125V17.875C10.0833 18.634 9.46725 19.25 8.70825 19.25Z"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
