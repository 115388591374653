<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 4.645C21 3.736 20.171 3 19.149 3H4.851C3.829 3 3 3.736 3 4.645V19.354C3 20.264 3.829 21 4.851 21H19.149C20.171 21 21 20.264 21 19.355V4.645V4.645Z"
      stroke="#50535B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.81494 5.49012C5.81194 5.49012 5.80994 5.49212 5.80994 5.49512C5.80994 5.49812 5.81194 5.50012 5.81494 5.50012C5.81794 5.50012 5.81994 5.49812 5.81994 5.49512C5.81994 5.49212 5.81794 5.49012 5.81494 5.49012"
      stroke="#50535B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.35803 5.49012C8.35503 5.49012 8.35303 5.49212 8.35303 5.49512C8.35303 5.49812 8.35603 5.50012 8.35803 5.50012C8.36103 5.50012 8.36303 5.49812 8.36303 5.49512C8.36303 5.49212 8.36103 5.49012 8.35803 5.49012"
      stroke="#50535B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.897 5.49012C10.894 5.49012 10.892 5.49212 10.892 5.49512C10.892 5.49812 10.894 5.50012 10.897 5.50012C10.9 5.50012 10.902 5.49812 10.902 5.49512C10.902 5.49212 10.9 5.49012 10.897 5.49012"
      stroke="#50535B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.03601 8H20.99"
      stroke="#50535B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.78 12.25H11.52"
      stroke="#50535B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.56276 12.0713C7.66039 12.1689 7.66039 12.3272 7.56276 12.4248C7.46513 12.5225 7.30684 12.5225 7.20921 12.4248C7.11158 12.3272 7.11158 12.1689 7.20921 12.0713C7.30684 11.9736 7.46513 11.9736 7.56276 12.0713"
      stroke="#50535B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.86 16.75H11.6"
      stroke="#50535B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.65078 16.5752C7.74841 16.6728 7.74841 16.8311 7.65078 16.9287C7.55314 17.0264 7.39485 17.0264 7.29722 16.9287C7.19959 16.8311 7.19959 16.6728 7.29722 16.5752C7.39485 16.4775 7.55314 16.4775 7.65078 16.5752"
      stroke="#50535B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
