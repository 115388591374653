<template>
  <v-app
    id="inspire"
    :style="{
      background:
        $vuetify.theme.themes[$vuetify.theme.dark === true ? 'dark' : 'light']
          .background,
    }"
  >
    <transition-page>
      <router-view />
    </transition-page>
  </v-app>
</template>

<script>
import TransitionPage from "@/components/Layout/TransitionPage";

export default {
  name: "App",
  components: { TransitionPage },
};
</script>
