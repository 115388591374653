<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 6.5H10.5V10.5H6.5V6.5Z"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 14.5H10.5V18.5H6.5V14.5Z"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5 10.5H14.5V6.5L18.5 6.5V10.5Z"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5354 16.4646C16.5549 16.4841 16.5549 16.5158 16.5354 16.5353C16.5158 16.5548 16.4842 16.5548 16.4646 16.5353C16.4451 16.5158 16.4451 16.4841 16.4646 16.4646C16.4842 16.4451 16.5158 16.4451 16.5354 16.4646"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.5354 14.4646C14.5549 14.4841 14.5549 14.5158 14.5354 14.5353C14.5158 14.5548 14.4842 14.5548 14.4646 14.5353C14.4451 14.5158 14.4451 14.4841 14.4646 14.4646C14.4842 14.4451 14.5158 14.4451 14.5354 14.4646"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.5354 18.4646C14.5549 18.4841 14.5549 18.5158 14.5354 18.5353C14.5158 18.5548 14.4842 18.5548 14.4646 18.5353C14.4451 18.5158 14.4451 18.4841 14.4646 18.4646C14.4842 18.4451 14.5158 18.4451 14.5354 18.4646"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5354 18.4646C18.5549 18.4841 18.5549 18.5158 18.5354 18.5353C18.5158 18.5548 18.4842 18.5548 18.4646 18.5353C18.4451 18.5158 18.4451 18.4841 18.4646 18.4646C18.4842 18.4451 18.5158 18.4451 18.5354 18.4646"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.0005 8.478V5C22.0005 3.895 21.1055 3 20.0005 3H16.5225"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.478 3H5C3.895 3 3 3.895 3 5V8.478"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 16.522V20C3 21.105 3.895 22 5 22H8.478"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5225 22H20.0005C21.1055 22 22.0005 21.105 22.0005 20V16.522"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5354 14.4646C18.5549 14.4841 18.5549 14.5158 18.5354 14.5353C18.5158 14.5548 18.4842 14.5548 18.4646 14.5353C18.4451 14.5158 18.4451 14.4841 18.4646 14.4646C18.4842 14.4451 18.5158 14.4451 18.5354 14.4646"
      stroke="#93959D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
