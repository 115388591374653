<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.49658"
      y="2.99622"
      width="18.0075"
      height="18.0075"
      rx="5"
      stroke="#3E4148"
      stroke-width="1.5"
    />
    <path
      d="M9.43867 11.2347L8.29061 12.3827C7.78314 12.8902 7.49805 13.5784 7.49805 14.2961C7.49805 15.0138 7.78314 15.702 8.29061 16.2095V16.2095C8.79808 16.717 9.48635 17.0021 10.204 17.0021C10.9217 17.0021 11.61 16.717 12.1174 16.2095L13.2655 15.0615"
      stroke="#3E4148"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.9692 13.5307L13.648 10.8519"
      stroke="#3E4148"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5616 12.7654L11.7349 8.93855L12.8829 7.79049C13.3904 7.28302 14.0787 6.99792 14.7963 6.99792C15.514 6.99792 16.2023 7.28302 16.7097 7.79049V7.79049C17.2172 8.29796 17.5023 8.98623 17.5023 9.7039C17.5023 10.4216 17.2172 11.1098 16.7097 11.6173L15.5616 12.7654Z"
      stroke="#3E4148"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
