<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 7H11"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 11H16"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 15H16"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 4.778V19.5C4 20.243 4.782 20.727 5.447 20.394L6.218 20.008C6.5 19.867 6.831 19.867 7.112 20.008L8.885 20.894C9.166 21.035 9.498 21.035 9.779 20.894L11.552 20.008C11.833 19.867 12.165 19.867 12.446 20.008L14.219 20.894C14.501 21.035 14.832 21.035 15.114 20.894L16.885 20.008C17.167 19.867 17.498 19.867 17.78 20.008L18.552 20.394C19.218 20.727 20 20.243 20 19.5V4.778C20 3.796 19.204 3 18.222 3H5.778C4.796 3 4 3.796 4 4.778Z"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.874 6.875C7.806 6.875 7.749 6.931 7.75 7C7.75 7.069 7.806 7.125 7.875 7.125C7.944 7.125 8 7.069 8 7C8 6.931 7.944 6.875 7.874 6.875"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.874 10.875C7.805 10.875 7.749 10.931 7.75 11C7.75 11.069 7.806 11.125 7.875 11.125C7.944 11.125 8 11.069 8 11C8 10.931 7.944 10.875 7.874 10.875"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.874 14.875C7.805 14.875 7.749 14.931 7.75 15C7.75 15.069 7.806 15.125 7.875 15.125C7.944 15.125 8 15.069 8 15C8 14.931 7.944 14.875 7.874 14.875"
      :stroke="clr"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
