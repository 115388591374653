<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.5" cy="10" r="9.5" fill="#F2F2F0" stroke="#DBDCDD" />
  </svg>
</template>
