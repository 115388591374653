<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8333 4.33334L6.5 11.6667L3.16666 8.33334"
      stroke="#8A6DDE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
