<script>
export default {
  props: ["clr"],
};
</script>

<template>
  <svg
    width="95"
    height="94"
    viewBox="0 0 95 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.4573 2.75419C45.9799 1.3903 49.0201 1.39029 51.5427 2.75419L67.9299 11.6144L83.7967 21.376C86.2391 22.8787 87.7592 25.5116 87.8393 28.3782L88.3598 47L87.8393 65.6218C87.7592 68.4884 86.2391 71.1213 83.7967 72.624L67.9299 82.3856L51.5427 91.2458C49.0201 92.6097 45.9799 92.6097 43.4573 91.2458L27.0701 82.3856L11.2033 72.624C8.7609 71.1213 7.24078 68.4884 7.16066 65.6218L6.64019 47L7.16066 28.3782C7.24078 25.5116 8.76089 22.8787 11.2033 21.376L27.0701 11.6144L43.4573 2.75419Z"
      stroke="#F2F2F0"
    />
    <path
      d="M47.9429 15.5C49.0057 15.5 50.0499 15.7791 50.971 16.3095L50.9708 16.3098L72.845 28.9389L72.8455 28.9392C73.7698 29.4715 74.5375 30.238 75.0713 31.1615C75.605 32.085 75.886 33.1329 75.8857 34.1996V34.1997V59.4643V59.4645C75.886 60.53 75.6058 61.5769 75.0731 62.4998C74.5405 63.4227 73.7742 64.189 72.8514 64.7218L50.9772 77.3509C50.0547 77.8836 49.0081 78.164 47.9429 78.164C46.8776 78.164 45.8311 77.8836 44.9085 77.3509L23.0343 64.7218C22.1115 64.189 21.3453 63.4227 20.8126 62.4998C20.28 61.5769 19.9997 60.53 20 59.4645V59.4643L20 34.1997L20 34.1995C19.9997 33.1339 20.28 32.0871 20.8126 31.1642C21.3453 30.2413 22.1115 29.4749 23.0343 28.9422L22.7843 28.5091L23.0343 28.9422L44.9085 16.313C45.8311 15.7804 46.8776 15.5 47.9429 15.5Z"
      stroke="#3889D0"
    />
    <path
      d="M38.5 35H56.5"
      stroke="#3889D0"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.5 59H56.5"
      stroke="#3889D0"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.5 35V41C41.5 41.945 41.944 42.833 42.7 43.4L47.5 47L52.3 43.4C53.056 42.833 53.5 41.945 53.5 41V35"
      stroke="#3889D0"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.5 59V53C53.5 52.055 53.056 51.167 52.3 50.6L47.5 47L42.7 50.6C41.944 51.167 41.5 52.055 41.5 53V59"
      stroke="#3889D0"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
